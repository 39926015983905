var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container container-fluid",attrs:{"id":"section-content"}},[_c('div',{staticClass:"bar-back"},[_c('router-link',{attrs:{"to":"/member/lottery"}},[_c('i',{staticClass:"fas fa-chevron-left"}),_vm._v(" แทงหวย")])],1),_c('div',{staticClass:"p-2 w-100 bg-light main-content align-self-stretch",staticStyle:{"min-height":"calc((100vh - 140px) - 50px)"}},[_c('div',{staticClass:"bgwhitealpha text-secondary shadow-sm rounded p-2 px-3 xtarget col-lotto mb-5"},[_c('h3',[_vm._v(_vm._s(_vm.yeeki.subCatName))]),_c('div',{staticClass:"row"},[_vm._l((_vm.getSubCatYeekiAviliableList
              .filter((e) => !_vm.getIsCloseYeekee(e.timeEnd, (_vm.getSubCatYeekiAviliableList.length > 88) ? 5 : 15))
              .reverse()),function(item){return _c('div',{key:item.id,staticClass:"col-6 col-sm-6 col-md-6 col-lg-3"},[_c('router-link',{attrs:{"to":_vm.getIsCloseYeekee(item.timeEnd, 2)
                  ? `/member/lottery/yeeki-result/${_vm.catalogId}/${item.yeekiRoundID}`
                  : (_vm.getIsCloseYeekee(item.timeEnd, 0) ? `/member/lottery/yeeki-fire/${_vm.catalogId}/${item.yeekiRoundID}` : `/member/lottery/yeeki/${_vm.catalogId}/${item.yeekiRoundID}`)}},[_c('div',{staticClass:"lotto-card",class:_vm.getIsClose(item.timeEnd) ? 'lotto-close' : ''},[_c('div',{staticClass:"ribbon-lotto",class:(_vm.getSubCatYeekiAviliableList.length > 88) ? 'ribbon-lotto-express' : ''},[_c('span',{},[_vm._v(_vm._s(item.yeekiRoundNum))])]),_c('div',{staticClass:"lotto-head",class:(_vm.getSubCatYeekiAviliableList.length > 88) ? 'lotto-yeekee-express' : 'lotto-yeekee'},[_c('h5',[_vm._v(_vm._s(_vm.yeeki.subCatName))]),_c('span',{staticClass:"badge badge-dark"},[_vm._v("ปิดรับ "+_vm._s(_vm.getCloseTime(item.timeEnd)))])]),(_vm.getIsClose(item.timeEnd))?_c('div',{staticClass:"lotto-time"},[_c('i',{staticClass:"fas fa-ban"}),_c('span',[_vm._v("ยังไม่รับแทง")])]):_c('div',{staticClass:"lotto-time text-success"},[_c('i',{staticClass:"sn-icon sn-icon--daily2"}),_c('span',{staticClass:"countdown",attrs:{"data-finaldate":"1610980200000"},domProps:{"innerHTML":_vm._s(_vm.calTime(item.timeEnd))}})])])])],1)}),_vm._l((_vm.getSubCatYeekiAviliableList
              .filter((e) => _vm.getIsCloseYeekee(e.timeEnd, 2))
              .reverse()),function(item){return _c('div',{key:item.id,staticClass:"col-6 col-sm-6 col-md-6 col-lg-3"},[_c('router-link',{attrs:{"to":_vm.getIsCloseYeekee(item.timeEnd, 2)
                  ? `/member/lottery/yeeki-result/${_vm.catalogId}/${item.yeekiRoundID}`
                  : `/member/lottery/yeeki/${_vm.catalogId}/${item.yeekiRoundID}`}},[_c('div',{staticClass:"lotto-card",class:_vm.getIsCloseYeekee(item.timeEnd, 2) ? 'lotto-close' : ''},[_c('div',{staticClass:"ribbon-lotto"},[_c('span',{},[_vm._v(_vm._s(item.yeekiRoundNum))])]),_c('div',{staticClass:"lotto-head lotto-yeekee"},[_c('h5',[_vm._v(_vm._s(_vm.yeeki.subCatName))]),_c('span',{staticClass:"badge badge-dark"},[_vm._v("ปิดรับ "+_vm._s(_vm.getCloseTime(item.timeEnd))+" ")])]),(_vm.getIsCloseYeekee(item.timeEnd, 2))?_c('div',{staticClass:"lotto-time"},[_c('i',{staticClass:"fas fa-ban"}),_c('span',[_vm._v("ยังไม่รับแทง")])]):_c('div',{staticClass:"lotto-time text-success"},[_c('i',{staticClass:"sn-icon sn-icon--daily2"}),_c('span',{staticClass:"countdown",attrs:{"data-finaldate":"1610980200000"},domProps:{"innerHTML":_vm._s(_vm.calTime(item.timeEnd))}})])])])],1)})],2)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }